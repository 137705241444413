<template>
  <c-box id="kuisioner-2">
    <Header />
    <c-box paddingTop="30px" paddingBottom="80px" :px="['20px', '0']">
      <c-box w="100%" maxWidth="1200px" mx="auto">
        <Breadcrumb
          :items="[
            {
              label: 'Beranda',
              href: $router.resolve({ name: 'client.index' }).href,
            },
            {
              label: 'Kuisioner',
              href: $router.resolve({ name: 'client.kuisioner' }).href,
              isCurrent: true,
            },
          ]"
        />
        <hr v-chakra mt="30px" />
        <Steps :current="2" :total="7" v-chakra mt="30px" />

        <c-box w="100%" maxWidth="590px" mx="auto" marginTop="50px">
          <c-box textAlign="center">
            <c-text
              color="brand.900"
              fontFamily="roboto"
              textTransform="uppercase"
              fontWeight="700"
              fontSize="16px"
              marginBottom="10px"
            >
              Kuisioner (2/7)
            </c-text>
            <c-heading
              fontWeight="600"
              fontSize="36px"
              lineHeight="54px"
              color="black.900"
            >
              Data Antropometri (Ukuran &amp; Komposisi Tubuh)
            </c-heading>
          </c-box>
          <c-box marginTop="80px">
            <form @submit.prevent="submit">
              <c-form-control
                :is-disabled="!isEditable"
                marginBottom="30px"
                is-required
                :is-invalid="$v.weight.$invalid"
              >
                <c-form-label
                  fontSize="14px"
                  color="#555555"
                  fontWeigh="400"
                  fontFamily="Roboto"
                >
                  Berat Badan
                </c-form-label>
                <c-input-group size="md">
                  <c-input
                    type="number"
                    id="weight"
                    placeholder="Masukkan Berat Badan"
                    height="62px"
                    paddingRight="70px"
                    v-model="weight"
                    required
                  />
                  <c-input-right-element
                    width="4.5rem"
                    mt="10px"
                    marginRight="20px"
                  >
                    <c-text color="darkGray.900" fontSize="18px">kg</c-text>
                  </c-input-right-element>
                </c-input-group>
                <c-form-helper-text
                  v-if="!$v.weight.maxValue"
                  color="red.500"
                  fontSize="12px"
                >
                  Maxium value is {{ $v.weight.$params.maxValue.max }}
                </c-form-helper-text>
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                marginBottom="30px"
                is-required
                :is-invalid="$v.height.$invalid"
              >
                <c-form-label
                  fontSize="14px"
                  color="#555555"
                  fontWeigh="400"
                  fontFamily="Roboto"
                >
                  Tinggi Badan
                </c-form-label>
                <c-input-group size="md">
                  <c-input
                    id="height"
                    placeholder="Masukkan Tinggi Badan"
                    height="62px"
                    type="number"
                    paddingRight="70px"
                    v-model="height"
                    required
                  />
                  <c-input-right-element
                    width="4.5rem"
                    mt="10px"
                    marginRight="20px"
                  >
                    <c-text color="darkGray.900" fontSize="18px">cm</c-text>
                  </c-input-right-element>
                </c-input-group>
                <c-form-helper-text
                  v-if="!$v.height.maxValue"
                  color="red.500"
                  fontSize="12px"
                >
                  Maxium value is {{ $v.height.$params.maxValue.max }}
                </c-form-helper-text>
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                marginBottom="30px"
                is-required
                :is-invalid="$v.waistSize.$invalid"
              >
                <c-form-label
                  fontSize="14px"
                  color="#555555"
                  fontWeigh="400"
                  fontFamily="Roboto"
                >
                  Lingkar Pinggang
                </c-form-label>
                <c-input-group size="md">
                  <c-input
                    id="waistSize"
                    placeholder="Masukkan Lingkar Pinggang"
                    height="62px"
                    type="text"
                    v-model="waistSize"
                    required
                  />
                  <c-input-right-element
                    width="4.5rem"
                    mt="10px"
                    marginRight="20px"
                  >
                    <c-text color="darkGray.900" fontSize="18px">cm</c-text>
                  </c-input-right-element>
                </c-input-group>
                <c-form-helper-text
                  v-if="!$v.waistSize.maxValue"
                  color="red.500"
                  fontSize="12px"
                >
                  Maxium value is {{ $v.waistSize.$params.maxValue.max }}
                </c-form-helper-text>
              </c-form-control>
              <c-text
                color="brand.900"
                fontFamily="roboto"
                fontWeight="700"
                fontSize="18px"
                marginBottom="30px"
              >
                Hasil Pengukuran Komposisi Tubuh
              </c-text>
              <c-form-control
                :is-disabled="!isEditable"
                marginBottom="30px"
                :is-invalid="$v.fat.$invalid"
              >
                <c-form-label
                  fontSize="14px"
                  color="#555555"
                  fontWeigh="400"
                  fontFamily="Roboto"
                >
                  Persen Lemak (Body Fat Percentage)
                </c-form-label>
                <c-input-group size="md">
                  <c-input
                    id="fatPercent"
                    placeholder="Persen Lemak (Body Fat Percentage)"
                    height="62px"
                    type="text"
                    paddingRight="70px"
                    v-model="fat"
                  />
                  <c-input-right-element
                    width="4.5rem"
                    mt="10px"
                    marginRight="20px"
                  >
                    <c-text color="darkGray.900" fontSize="18px">%</c-text>
                  </c-input-right-element>
                </c-input-group>
                <c-form-helper-text
                  v-if="!$v.fat.maxValue"
                  color="red.500"
                  fontSize="12px"
                >
                  Maxium value is {{ $v.fat.$params.maxValue.max }}
                </c-form-helper-text>
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                marginBottom="30px"
                :is-invalid="$v.muscleMass.$invalid"
              >
                <c-form-label
                  fontSize="14px"
                  color="#555555"
                  fontWeigh="400"
                  fontFamily="Roboto"
                >
                  Massa Otot (Muscle Mass)
                </c-form-label>
                <c-input-group size="md">
                  <c-input
                    id="muscleMass"
                    placeholder="Masukkan Massa Otot (Muscle Mass)"
                    height="62px"
                    type="text"
                    paddingRight="70px"
                    v-model="muscleMass"
                  />
                  <c-input-right-element
                    width="4.5rem"
                    mt="10px"
                    marginRight="20px"
                  >
                    <c-text color="darkGray.900" fontSize="18px">kg</c-text>
                  </c-input-right-element>
                </c-input-group>
                <c-form-helper-text
                  v-if="!$v.muscleMass.maxValue"
                  color="red.500"
                  fontSize="12px"
                >
                  Maxium value is {{ $v.muscleMass.$params.maxValue.max }}
                </c-form-helper-text>
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                marginBottom="30px"
                :is-invalid="$v.bodyWater.$invalid"
              >
                <c-form-label
                  fontSize="14px"
                  color="#555555"
                  fontWeigh="400"
                  fontFamily="Roboto"
                >
                  Persen Cairan Tubuh (Body Water Percentage)
                </c-form-label>
                <c-input-group size="md">
                  <c-input
                    id="percentLiquid"
                    placeholder="Masukkan Persen Cairan Tubuh (Body Water Percentage)"
                    height="62px"
                    type="text"
                    v-model="bodyWater"
                  />
                  <c-input-right-element
                    width="4.5rem"
                    mt="10px"
                    marginRight="20px"
                  >
                    <c-text color="darkGray.900" fontSize="18px">%</c-text>
                  </c-input-right-element>
                </c-input-group>
                <c-form-helper-text
                  v-if="!$v.bodyWater.maxValue"
                  color="red.500"
                  fontSize="12px"
                >
                  Maxium value is {{ $v.bodyWater.$params.maxValue.max }}
                </c-form-helper-text>
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                marginBottom="30px"
                :is-invalid="$v.visceralFat.$invalid"
              >
                <c-form-label
                  fontSize="14px"
                  color="#555555"
                  fontWeigh="400"
                  fontFamily="Roboto"
                >
                  Lemak Visceral (Visceral Fat)
                </c-form-label>
                <c-input-group size="md">
                  <c-input
                    id="noTlpn"
                    placeholder="Masukkan Lemak Visceral (Visceral Fat)"
                    height="62px"
                    type="text"
                    v-model="visceralFat"
                  />
                </c-input-group>
                <c-form-helper-text
                  v-if="!$v.visceralFat.maxValue"
                  color="red.500"
                  fontSize="12px"
                >
                  Maxium value is {{ $v.visceralFat.$params.maxValue.max }}
                </c-form-helper-text>
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                marginBottom="30px"
                :is-invalid="$v.boneMass.$invalid"
              >
                <c-form-label
                  fontSize="14px"
                  color="#555555"
                  fontWeigh="400"
                  fontFamily="Roboto"
                >
                  Massa Tulang (Bone Mass)
                </c-form-label>
                <c-input-group size="md">
                  <c-input
                    id="boneMass"
                    placeholder="Masukkan Massa Tulang (Bone Mass)"
                    height="62px"
                    type="text"
                    v-model="boneMass"
                  />
                  <c-input-right-element
                    width="4.5rem"
                    mt="10px"
                    marginRight="20px"
                  >
                    <c-text color="darkGray.900" fontSize="18px">kg</c-text>
                  </c-input-right-element>
                </c-input-group>
                <c-form-helper-text
                  v-if="!$v.boneMass.maxValue"
                  color="red.500"
                  fontSize="12px"
                >
                  Maxium value is {{ $v.boneMass.$params.maxValue.max }}
                </c-form-helper-text>
              </c-form-control>
              <c-form-control
                :is-disabled="!isEditable"
                marginBottom="30px"
                :is-invalid="$v.basalMetabolicRate.$invalid"
              >
                <c-form-label
                  fontSize="14px"
                  color="#555555"
                  fontWeigh="400"
                  fontFamily="Roboto"
                >
                  Kebutuhan Energi Basal atau BMR (Basal Metabolic Rate)
                </c-form-label>
                <c-input-group size="md">
                  <c-input
                    id="energyRequirement"
                    placeholder="Masukkan Kebutuhan Energi Basal atau BMR (Basal Metabolic Rate)"
                    height="62px"
                    type="text"
                    v-model="basalMetabolicRate"
                  />
                </c-input-group>
              </c-form-control>

              <DinamisForm v-model="questions" />

              <c-flex
                justifyContent="space-between"
                alignItems="center"
                marginTop="30px"
              >
                <c-button
                  left-icon="chevron-left"
                  backgroundColor="brand.900"
                  color="white"
                  borderRadius="100px"
                  variant="solid"
                  px="30px"
                  as="router-link"
                  :to="{ name: 'client.kuisioner1', params: $route.params }"
                >
                  Kembali
                </c-button>
                <c-button
                  right-icon="chevron-right"
                  backgroundColor="brand.900"
                  color="white"
                  borderRadius="100px"
                  variant="solid"
                  px="30px"
                  type="submit"
                >
                  Selanjutnya
                </c-button>
              </c-flex>
            </form>
          </c-box>
        </c-box>
      </c-box>
    </c-box>
    <Footer />
  </c-box>
</template>

<script>
import Header from "@/components/header/index.vue"
import Footer from "@/components/Footer.vue"
import { mapState } from "vuex"
import { numeric, required, maxValue } from "vuelidate/lib/validators"
import Breadcrumb from "@/components/breadcrumb"
import Steps from "@/components/steps"
import DinamisForm from "@/views/client/kuisioner/dinamis-form"

export default {
  name: "kuisioner2",
  components: {
    DinamisForm,
    Breadcrumb,
    Steps,
    Header,
    Footer,
  },
  data() {
    return {
      weight: null,
      height: null,
      waistSize: null,
      fat: null,
      muscleMass: null,
      bodyWater: null,
      visceralFat: null,
      boneMass: null,
      basalMetabolicRate: null,
      questions: [],
    }
  },
  validations: {
    weight: { required, numeric, maxValue: maxValue(250) },
    height: { required, numeric, maxValue: maxValue(250) },
    waistSize: { required, numeric, maxValue: maxValue(150) },
    fat: { numeric, maxValue: maxValue(50) },
    muscleMass: { numeric, maxValue: maxValue(200) },
    bodyWater: { numeric, maxValue: maxValue(80) },
    visceralFat: { numeric, maxValue: maxValue(70) },
    boneMass: { numeric, maxValue: maxValue(10) },
    basalMetabolicRate: { numeric },
  },
  computed: {
    ...mapState({
      kuisData: (s) => s.kuisioner.anthropometryData,
    }),
    value() {
      return {
        weight: this.weight,
        height: this.height,
        waistSize: this.waistSize,
        fat: this.fat,
        muscleMass: this.muscleMass,
        bodyWater: this.bodyWater,
        visceralFat: this.visceralFat,
        boneMass: this.boneMass,
        basalMetabolicRate: this.basalMetabolicRate,
      }
    },
    isEditable() {
      return this.$route.params.isEditable ?? true
    },
    answers() {
      return this.questions.map((it) => ({
        questionId: it.id,
        answer: it.answer,
        question: it.question,
      }))
    },
  },
  watch: {
    kuisData: {
      immediate: true,
      handler(val) {
        if (val == null) return

        this.weight = val.weight
        this.height = val.height
        this.waistSize = val.waistSize
        this.fat = val.fat
        this.muscleMass = val.muscleMass
        this.bodyWater = val.bodyWater
        this.visceralFat = val.visceralFat
        this.boneMass = val.boneMass
        this.basalMetabolicRate = val.basalMetabolicRate
      },
    },
    programId: {
      immediate: true,
      async handler(programId) {
        if (programId == null) return;

        await this.$store.dispatch(
            "kuisioner/getV2ClientForCategory",
            "anthropometry_data"
        )
        await this.$store.dispatch("kuisioner/getV2ClientDraft", programId)
        this.questions = this.$store.getters["kuisioner/questionFor"]("anthropometry_data")
      },
    },
  },
  methods: {
    async submit() {
      this.$v.$touch()
      if (this.$v.$invalid) return

      await this.$store.dispatch("kuisioner/setAnthropometryData", {
        programId: this.$route.params.programId,
        value: this.value,
        answers: this.answers,
      })
      await this.$router.push({
        name: "client.kuisioner3",
        params: this.$route.params,
      })
    },
  },
}
</script>

<style></style>
